import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

import { classnames } from 'begonya/functions'

import { useApp } from '@/core/contexts/app'

import { Column, Container, Row } from '@/components/base/gridview'
import Image from '@/components/base/image/Image'
import Anchor from '@/components/base/anchor/Anchor'
import Button from '@/components/base/button/Button'

import { AnswerProps } from './type'

const Answers: React.FunctionComponent<AnswerProps> = ({ title, data }) => {
  const app = useApp()

  const [activeIndex, setActiveIndex] = useState(0)
  const [contentHeight, setContentHeight] = useState(0)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.offsetHeight)
    }
  }, [contentRef])

  function charLimit(text: string, maxLength: number) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...'
    }
    return text
  }

  const handleItemClick = (index: number) => {
    setActiveIndex(index)
  }

  return (
    <div className={classnames('answers section')}>
      <Container size="wide">
        <Row xs={{ align: 'center' }}>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 12 }}
            xl={{ size: 12 }}
          >
            <div className="section-title text-center">{title}</div>
          </Column>
        </Row>
        <Row>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 12 }}
            xl={{ size: 12 }}
            className="answers-list"
          >
            {data?.map((item, index) => (
              <div
                key={index}
                className={classnames(
                  'answers-list-item',
                  index === activeIndex
                    ? 'answers-list-item--active'
                    : 'answers-list-item--disable'
                )}
              >
                <Button onClick={() => handleItemClick(index)}>
                  <figure>
                    <Image {...item.icon} alt={item.title} />
                  </figure>
                  <div className="title">{item.title}</div>
                </Button>
              </div>
            ))}
          </Column>
        </Row>
        <Row>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 12 }}
            xl={{ size: 12 }}
            className="answers-list-content"
            style={{ height: `${contentHeight}px` }}
          >
            {data?.map((item, index) => (
              <div
                ref={contentRef}
                key={index}
                className={classnames(
                  'answers-list-content-item',
                  index === activeIndex
                    ? 'answers-list-content-item--active'
                    : 'answers-list-content-item--disable'
                )}
              >
                <Row>
                  <Column
                    xs={{ size: 12 }}
                    sm={{ size: 12 }}
                    md={{ size: 12 }}
                    lg={{ size: 6 }}
                    xl={{ size: 6 }}
                    className="text-area"
                  >
                    <div className="title">{item.title}</div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: charLimit(item.desc, 400),
                      }}
                    />
                    <Anchor className="more" {...item.link}>
                      {app.settings.translations['read-more']}
                    </Anchor>
                  </Column>
                  <Column
                    xs={{ size: 12 }}
                    sm={{ size: 12 }}
                    md={{ size: 12 }}
                    lg={{ size: 6 }}
                    xl={{ size: 6 }}
                  >
                    <figure>
                      <Image {...item.image} alt={item.title} />
                    </figure>
                  </Column>
                </Row>
              </div>
            ))}
          </Column>
        </Row>
      </Container>
    </div>
  )
}

export default Answers
